<template>
  <div>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';

export default {
  name: 'LandingView',
  computed: {
    ...mapGetters('auth',[
      'isAuthenticated',
    ]),
  },
};
</script>
