import { createRouter, createWebHistory } from 'vue-router';
import {
  LandingView,
  Runs,
  Login,
} from '@/views';
import store from '@/store';
import { COMPANY_NAME } from '@/common/config';

const routes = [
  {
    path: '/',
    name: 'home',
    strict: true,
    component: LandingView,
    meta: {
      title: `${COMPANY_NAME} - homepage`,
    },
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: `${COMPANY_NAME} - login`,
    },
    component: Login,
  },
  {
    path: '/runs',
    name: 'runs',
    // strict: true,
    meta: {
      protected: true,
      title: `${COMPANY_NAME} - run SMconverter`,
    },
    component: Runs
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  // console.log(store.getters.isAuthenticated, to)

  if (!store.getters['auth/isAuthenticated'] && !!to.meta.protected) {
    return { name: 'home' };
  }

  if (store.getters['auth/isAuthenticated'] && to.name != 'runs') {
    return { name: 'runs' };
  }

  document.title = to.meta.title || COMPANY_NAME;

  return undefined;
});

export default router;
